import React from 'react'
import ProjectLanguages from './ProjectLanguages'
import ProjectLinks from './ProjectLinks'

// data
import data from '../Data/data'

// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/scrollbar'
import 'swiper/scss/effect-fade'

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react'
// import Swiper core and required components
import SwiperCore, {Navigation, Pagination, Autoplay} from 'swiper'
// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay])

function Slider() {
  const projects = data.kirankumargonti.latestProjects
  return (
    <Swiper
      className='swiper-container'
      spaceBetween={0}
      slidesPerView={1}
      navigation
      autoplay
      effect='fade'
      pagination={{clickable: true}}
      onSlideChange={() => {
        return true
      }}
      onSwiper={(swiper) => {
        return swiper
      }}
    >
      {projects.map((project) => (
        <SwiperSlide
          className='swiper-slide'
          id='slider'
          key={project.projectID}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='slider_inner'>
                  <div className='slider_text'>
                    <h1>{project.projectName}</h1>
                    <p>{project.projectDescription}</p>
                    <ProjectLanguages languages={project.projectTechStack} />
                    <ProjectLinks links={project.projectLinks} />
                  </div>
                  <img src={project.projectImage} alt='' />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Slider
