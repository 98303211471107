import React from 'react';

// data
import data from '../Data/data';
import {SiGithub, SiCodepen, SiHackerrank} from 'react-icons/si';
const ProjectsBanner = () => {
  const {github, codePen, hackerRank} = data.kirankumargonti.socialLinks;
  return (
    <section className='projects_banner'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='projects_content'>
              <h1>Talk is Cheap</h1>
              <h1>Show me the Code</h1>
              <p>- Linus Torvalds</p>
              <div className='projects_links'>
                <a
                  href={github}
                  rel='noopener noreferrer'
                  className='github_projects_link'
                >
                  <SiGithub className='icon' />
                </a>
                <a href={codePen} rel='noopener noreferrer'>
                  <SiCodepen className='icon' />
                </a>
                <a href={hackerRank} rel='noopener noreferrer'>
                  <SiHackerrank className='icon' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsBanner;
