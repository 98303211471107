import React from 'react'

const CustomButton = ({buttonName, onClick}) => {
  return (
    <button className='btn-stars btn' type='button' onClick={onClick}>
      <strong>{buttonName}</strong>
      <div id='container-stars'>
        <div id='stars'></div>
      </div>

      <div id='glow'>
        <div className='circle'></div>
        <div className='circle'></div>
      </div>
    </button>
  )
}

export default CustomButton
