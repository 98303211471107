import React, {useState, useEffect} from 'react'
import Logo from '../Assets/logo.png'

const Loading = () => {
  const [load, setLoad] = useState(true)
  window.addEventListener('load', (event) => {
    setLoad(false)
  })

  useEffect(() => {
    setTimeout(() => {
      setLoad(false)
    }, 10000)
  }, [])

  return (
    load && (
      <div class='ipl-progress-indicator' id='ipl-progress-indicator'>
        <div class='ipl-progress-indicator-head'>
          <div class='first-indicator'></div>
          <div class='second-indicator'></div>
        </div>
        <div class='insp-logo-frame'>
          <div className='main_wrapper'>
            <img src={Logo} alt='' />
          </div>
        </div>
      </div>
    )
  )
}

export default Loading
