import Resume from '../Assets/kirankumar_gonti_frontend_sde2.pdf'
import Logo from '../Assets/logo.png'
import {v4 as uuid} from 'uuid'

const data = {
  kirankumargonti: {
    websiteInformation: {
      homeTitle: 'Kirankumar Gonti | Frontend Developer - Web3',
      projectsTitle: 'Projects | Kirankumar Gonti',
      notFoundTitle: 'Error(404 Notfound) - Kirankumar Gonti',
      logo: Logo,
    },
    personalInformation: {
      name: 'Kirankumar Gonti',
      designation: 'Frontend Developer - Web3',
      shortBio:
        `I'm your pixel magician! ✨ Crafting interfaces that captivate like Martian landscapes & convert like space lasers. Let's make your vision legendary!. Ready to see your vision come alive? Let's chat!`,

      // 'Full Stack BlockChain Developer specializing in ( Solidity and React) development Experienced with all stages of the development cycle for dynamic web projects. Well-versed in numerous programming languages  Python, JavaScript, and Databases NoSQL and SQL.',
      // fullBio: `A freelancer based in Hyderabad, India. I'm graduated in a bachelor technology in the computer science stream. I'm a youtube also. I make videos related to web Development and programming. I'm open to getting any work or job related to Software as a freelancer or an employee at your company. I want to be placed in a Good company where I can explore my skills and I would like to improve my talent, work quality and gain experience.I believe in hard work because it pays off on what I am doing. I am flexible in doing multiple tasks`,
      fullBio: `I'm not just a frontend developer, I'm a builder of digital bridges. I seamlessly connect the power of traditional web development with the innovative possibilities of Web3, crafting interfaces that captivate users in both familiar and uncharted territories.
      
      From sleek, user-friendly interfaces to immersive metaverse experiences: My expertise spans both worlds. I wield frameworks like Next Js, React, and Typescript with precision, while also navigating the intricacies of Web3 development with technologies like Web3 Js, Ethers Js, Wagmi and Viem.
      
      Security, accessibility, and performance are my mantras. Whether you're building a dApp, a vibrant DAO community, or a groundbreaking DeFi solution, I ensure your users have a seamless and secure experience, regardless of their digital terrain.
      
      But I'm more than just code. I'm a collaborator and an innovator. I partner with you to understand your vision, translating it into experiences that not only captivate users but also drive real value.
      
      Ready to explore the boundless potential of Web3, or simply elevate your traditional web presence? Let's build something remarkable together.`,
      image:
        'https://ik.imagekit.io/kirankumargonti/kiran_eqh7QLF08.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083183633',
      resume: Resume,
      /* resume:
        'https://doc-0g-6c-docs.googleusercontent.com/docs/securesc/0ie7hmun9rc286ofgj8ua7mdd9488q1q/2p73unq3eo76s1upvt850obsm722v205/1601025525000/02493656482600388884/02493656482600388884/1BLm5MM7voMsmNrqxnPGBpS6oinChs4Ky?e=download&authuser=0&nonce=fsjsa12a7iqnc&user=02493656482600388884&hash=nt0fqg9l96ui4ksrp7quagf9p29aatlm', */
      phoneNumber: '+917569984640',
      email: 'kirankumar225rk@gmail.com',
      address: 'Hyderabad, Telangana, India',
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/kmunna2',
      linkedIn: 'https://www.linkedin.com/in/kirankumar-gonti-813870137/',
      github: 'https://github.com/kirankumargonti',
      codePen: 'https://codepen.io/kirankumargonti',
      instagram: 'https://www.instagram.com/kirankumar_gonti57/',
      hackerRank: 'https://www.hackerrank.com/kirankumar225rk',
      youtube: 'https://www.youtube.com/c/kirankumargonti',
      twitter: 'https://twitter.com/gontikirankumar',
    },
    dappProjects: [
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot_2022-09-13_at_21-15-23_UnicusOne_-_World_s_first_Multi_Chain_WaaS_RlhU2iUWc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663084684946',
        projectName: 'Unicus One',
        projectDescription:
          'Worlds first Multi Chain WaaS (Web3 as a Service) Platform for Metaverse, Gaming, and NFT Economy',
        projectLinks: {
          previewURL: 'https://unicus.one/',
        },
        projectTechStack: {},
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot_2022-09-13_at_21-33-17_Jungle_toh5lLq2J.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1663085057325',
        projectName: 'Jungle - NFT',
        projectDescription:
          'Worlds Largest NFT Marketplace built on Ethereum and polygon',
        projectLinks: {
          previewURL: 'https://gojungle.xyz/',
        },
        projectTechStack: {},
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/gol_-oNqjbVl8.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083168946',
        projectName: 'Game of Laughs',
        projectDescription:
          'A Meme to Earn platform that rewards their users for their time and efforts',
        projectLinks: {
          previewURL: 'https://www.gameoflaughs.com/',
        },
        projectTechStack: {},
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/cc_nzhjoTBbS2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083169039',
        projectName: 'Credit Capital',
        projectDescription: `Credit Capital is an uncollateralized lending DeFi
          protocol, powered by TRU. It allows lenders to earn high,
          stable returns on loaned cryptocurrencies`,
        projectLinks: {
          previewURL: 'https://creditcapital.io/',
        },
        projectTechStack: {},
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/crossing_XkIXibvg8F.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083169035',
        projectName: 'The Crossing',
        projectDescription:
          'This bridging service provides access to inter-blockchain liquidity',
        projectLinks: {
          previewURL: 'https://the-crossing.io/',
        },
        projectTechStack: {},
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot_2022-09-13_at_21-16-36_Volta_DAO_Sp8j3oexC.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663084674316',
        projectName: 'Volta DAO',
        projectDescription:
          'Volta Dao is a decentralized reserve currency operating on the Fantom network. ',
        projectLinks: {
          previewURL: 'https://app.voltadao.finance/stake',
        },
        projectTechStack: {},
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/katamas_zTmMsgkR8.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083168660',
        projectName: 'Kishimoto - Staking',
        projectDescription:
          'kishimoto and katsumi are teaming up to give tokens holders a diamond katana bag',
        projectLinks: {
          previewURL: 'https://zoom--clone--app.herokuapp.com',
        },
        projectTechStack: {},
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/dispatch_TWEn-LS11.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083168144',
        projectName: 'Dispatch',
        projectDescription:
          'Dispatch keeps every single holder up to date. Built for Web3 token teams & NFT projects.',
        projectLinks: {
          previewURL: 'https://iexfe.uat.rapidinnovation.tech/',
        },
        projectTechStack: {},
      },
    ],
    latestProjects: [
      {
        projectID: uuid(),
        projectImage:
          'https://i.ytimg.com/vi/4vXrlhON6Zw/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLApNP_ZE1AbPXHbMDhx8WYOWgKvTg',
        projectName: 'Google Clone',
        projectDescription:
          'Google Clone With React ( Hooks + Context API ) and Google Custom Search JSON API ( Programmable Search Engine ) ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/google-clone',
          previewURL: 'https://clone-9aeda.web.app/',
          download:
            'https://codeload.github.com/kirankumargonti/google-clone/zip/master',
        },
        projectTechStack: {
          html: 'html',
          css: 'css',
          sass: 'sass',
          react: 'react',
          firebase: 'firebase',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/pomodoro_timer/main/src/Assets/snap.jpg',
        projectName: 'Pomodoro Timer',
        projectDescription: 'A simple responsive Todo list with a timer ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/pomodoro_timer',
          download:
            'https://codeload.github.com/kirankumargonti/pomodoro_timer/zip/refs/heads/main',
          previewURL: 'https://kirankumargonti.github.io/pomodoro_timer/',
        },
        projectTechStack: {
          react: 'react',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/amazon_9KK7KsVU6r.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083168733',
        projectName: 'Amazon Clone',
        projectDescription:
          'Amazon clone app with React ( Hooks + Context API ) and Firebase Authentication ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/amazon-clone',
          previewURL: 'https://clone-ddfd4.web.app/',
          download:
            'https://codeload.github.com/kirankumargonti/amazon-clone/zip/master',
        },
        projectTechStack: {
          html: 'html',
          css: 'css',
          sass: 'sass',
          react: 'react',
          firebase: 'firebase',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot_2022-09-13_at_21-15-08__20___Facebook_WlGgbk3Zw.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663084685558',
        projectName: 'Facebook Clone',
        projectDescription:
          'Facebook Clone With React( Hooks + Context API) and Firebase Authentication',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/facebook-clone',
          previewURL: 'https://facebook-clone-96271.web.app/',
          download:
            'https://codeload.github.com/kirankumargonti/facebook-clone/zip/master',
        },
        projectTechStack: {
          html: 'html',
          css: 'css',
          sass: 'sass',
          react: 'react',
          firebase: 'firebase',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/tiktok_oOdqzDdI9.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663084905016',
        projectName: 'TikTok Clone',
        projectDescription:
          'TikTok Clone app with MERN ( MongoDB Express React Node ) Stack',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/tiktok-clone',
          previewURL: 'https://tiktok-clone-mern-b8cd7.web.app/',
          download:
            'https://codeload.github.com/kirankumargonti/tiktok-clone/zip/master',
        },
        projectTechStack: {
          react: 'react',
          sass: 'sass',
          firebase: 'firebase',
          mongodb: 'mongodb',
          expressjs: 'expressjs',
          nodejs: 'nodejs',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot_2022-09-13_at_9.15.56_PM_cHlbKUqi8.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663084698701',
        projectName: 'Zoom Clone',
        projectDescription:
          'Zoom Clone with Node Js, Express Js, Socket.io, Peer Js and ejs',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/zoom-clone',
          previewURL: 'https://zoom--clone--app.herokuapp.com',
          download:
            'https://codeload.github.com/kirankumargonti/zoom-clone/zip/main',
        },
        projectTechStack: {
          html: 'html',
          css: 'css',
          sass: 'sass',
          nodejs: 'nodejs',
          expressjs: 'expressjs',
        },
      },
    ],
    youtubeVideos: [
      {
        youtubeURL: 'https://www.youtube.com/c/kirankumargonti',
        youtubeVideosGithubRepo: 'https://github.com/kirankumargonti/youtube',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/NeHqamXt7Pk/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCXbwIg2Vz5uqYypvzeZwcFapSpMA',
        videoTitle: 'Build A Responsive Stock Photos Website Landing Page',
        videoURL: 'https://www.youtube.com/watch?v=NeHqamXt7Pk&t=955s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/E3pa_P4fYbc/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCnHT8_lKAiI3mlg_mzSP_z8ZWf0g',
        videoTitle:
          'Create A Responsive Side Navigation Bar | Vertical Navigation',
        videoURL: 'https://www.youtube.com/watch?v=E3pa_P4fYbc&t=86s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/faWtSiAnwcY/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCLm05b_5VoCR7q9EYFqi3Rkbg4DA',
        videoTitle: 'Create A Fullscreen Video Background',
        videoURL: 'https://www.youtube.com/watch?v=faWtSiAnwcY&t=86s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/hp1tDS-c55A/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCgL8YPEhhNeaIMXP1xGHt9eJW6Vg',
        videoTitle: 'Top 10 Visual Studio Code Extensions For Python',
        videoURL: 'https://www.youtube.com/watch?v=hp1tDS-c55A&t=4s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/2GBQs7FV7Ro/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLBi4zNksq5VLd9q0UIKxHSw5Ews8A',
        videoTitle: 'Create A Responsive Sign Up Form',
        videoURL: 'https://www.youtube.com/watch?v=2GBQs7FV7Ro&t=37s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/fakVrbVTpXs/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLAtQ2QrI-zHO19y6xJ_B85rVaS7ww',
        videoTitle: 'Create A Coming Soon | Website is Under Construction Page',
        videoURL: 'https://www.youtube.com/watch?v=fakVrbVTpXs&t=134s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/SrtjXxxMb4U/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLAGXBe8PVl7OC57V0jk1-POcxYp7g',
        videoTitle: 'Animated Image Slider With Zoom Out Effect ',
        videoURL: 'https://www.youtube.com/watch?v=SrtjXxxMb4U',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/4vXrlhON6Zw/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLApNP_ZE1AbPXHbMDhx8WYOWgKvTg',
        videoTitle: 'Build a Google Clone Design ',
        videoURL: 'https://www.youtube.com/watch?v=4vXrlhON6Zw&t=130s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/m-moQwfysdM/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLC2P2rK_2PFP57F0bTDKne876EYBg',
        videoTitle: 'Simple Responsive Countdown Timer With JavaScript',
        videoURL: 'https://www.youtube.com/watch?v=m-moQwfysdM&t=114s',
      },
    ],
    projects: [
      {
        projectID: uuid(),
        projectImage:
          'https://res.cloudinary.com/kirankumargonti/image/upload/v1616658387/Kirankumargonti/projects/latest_Projects/ki_fgvzsv.webp',
        projectName: 'Expense Tracker',
        projectDescription:
          'Expense Tracker With React Native with and Firebase Authentication',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/expense-tracker-react-native',
          download:
            'https://codeload.github.com/kirankumargonti/expense-tracker-react-native/zip/refs/heads/main',
        },
        projectTechStack: {
          reactnative: 'reactnative',
          firebase: 'firebase',
          expo: 'expo',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Voice Assistance App with Alan AI',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/voice-assistance-app-with-alan-ai/main/src/screenshot.jpg',
        projectDescription:
          'Give voice power to your web applications with alan ai this project covered topics: React.js, Alan AI, Chakra UI ',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/voice-assistance-app-with-alan-ai/tree/main',
          download:
            'https://codeload.github.com/kirankumargonti/voice-assistance-app-with-alan-ai/zip/main',
          previewURL:
            'https://kirankumargonti.github.io/voice-assistance-app-with-alan-ai/',
        },
        projectTechStack: {
          react: 'react',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Corona Virus Tracking Application',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/covid19-tracker/master/src/images/demo.png',
        projectDescription:
          'A corona tracker application. Covered topics: React.js, Chart.js, Material UI and much more.',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/covid19-tracker',
          download:
            'https://codeload.github.com/kirankumargonti/covid19-tracker/zip/master',
          previewURL: 'https://kirankumargonti.github.io/covid19-tracker/',
        },
        projectTechStack: {
          html: 'html',
          css: 'css',
          sass: 'sass',
          Javascript: 'javascript',
          react: 'react',
          materialui: 'materialui',
        },
      },
      {
        projectID: uuid(),
        projectName: '50 Projects in 50 Days',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/50ProjectsIn50Days/main/Expanding_Cards/Screenshot.jpg',
        projectDescription:
          'This repo is contains all web development projects for beginners and intermediate devs',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/50ProjectsIn50Days',
          download:
            'https://codeload.github.com/kirankumargonti/50ProjectsIn50Days/zip/main',
        },
        projectTechStack: {
          html: 'html',
          css: 'css',
          sass: 'sass',
          javascript: 'javascript',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Pixabay Clone',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/Image-and-video-finder-with-PixaBay-API-and-React/master/demo.jpg',
        projectDescription:
          'React & bootstrap UI application to find images and videos utilizing the PixaBay API',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Image-and-video-finder-with-PixaBay-API-and-React',
          download:
            'https://codeload.github.com/kirankumargonti/Image-and-video-finder-with-PixaBay-API-and-React/zip/master',
          previewURL:
            'https://kirankumargonti.github.io/Image-and-video-finder-with-PixaBay-API-and-React/',
        },
        projectTechStack: {
          html: 'html',
          css: 'css',
          react: 'react',
          bootstrap: 'bootstrap',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Corona Tracking App With Python',
        projectImage:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/768px-Python-logo-notext.svg.png',
        projectDescription:
          'Python web scraping app for tracking the corona virus cases ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/corona-virus-tracker',
          download:
            'https://codeload.github.com/kirankumargonti/corona-virus-tracker/zip/master',
        },

        projectTechStack: {
          python: 'python',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Login authentication with MERN Stack',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/MERN-stack-login-authentication/master/MERN.gif',
        projectDescription:
          'Simple User Registration and Login functionality with MongoDB, ExpressJs, React and NodeJs [MERN]',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/MERN-stack-login-authentication',
          download:
            'https://codeload.github.com/kirankumargonti/MERN-stack-login-authentication/zip/master',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          mongodb: 'mongodb',
          expressjs: 'expressjs',
          react: 'react',
          nodejs: 'nodejs',
          redux: 'redux',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Responsive Photography Website',
        projectImage:
          'https://res.cloudinary.com/kirankumargonti/image/upload/v1600856122/Kirankumargonti/projects/Screenshot_2020-09-23_Kiran_Digital_Studio_A_Multilevel_Digital_Studio_1_g2zk1g.jpg',
        projectDescription:
          'complete responsive PhotoStudio website which consists of different kind services of categories like albums, portrait, candid, and wedding, etc',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Responsive-Photography-Website',
          download:
            'https://codeload.github.com/kirankumargonti/Responsive-Photography-Website/zip/master',
          previewURL:
            'https://kirankumargonti.github.io/Responsive-Photography-Website/',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          bootstrap: 'bootstrap',
          javascript: 'javascript',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Nodejs Login App with Passport JS',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/nodejs_passport_login_app/master/public/images/demo.png',
        projectDescription:
          'Node & Express login app with passportjs authentication ',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/nodejs_passport_login_app',
          download:
            'https://codeload.github.com/kirankumargonti/nodejs_passport_login_app/zip/master',
        },

        projectTechStack: {
          nodejs: 'nodejs',
          html: 'html',
          css: 'css',
          bootstrap: 'bootstrap',
          expressjs: 'expressjs',
          mongodb: 'mongodb',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Random Password Generator ',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/Random-Password-Generator-JavaScript/master/image/1.png',
        projectDescription:
          'A Random Password Generator That will take Length, Characters, Numbers and Symbols as an option using JavaScript. ',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Random-Password-Generator-JavaScript',
          download:
            'https://codeload.github.com/kirankumargonti/Random-Password-Generator-JavaScript/zip/master',
          previewURL:
            'https://kirankumargonti.github.io/Random-Password-Generator-JavaScript/',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          javascript: 'javascript',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Weather Status App With React',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/Weather-Status-App-with-React/master/src/images/demo1.png',
        projectDescription:
          'React Weather Application shows us current weather status of all the cities on the earth',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Weather-Status-App-with-React',
          download:
            'https://codeload.github.com/kirankumargonti/Weather-Status-App-with-React/zip/master',
          previewURL:
            'https://kirankumargonti.github.io/Weather-Status-App-with-React/',
        },
        projectTechStack: {
          react: 'react',
          html: 'html',
          css: 'css',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Dynamic Landing Page With Time-JavaScript',
        projectImage:
          'https://res.cloudinary.com/kirankumargonti/image/upload/v1600918450/Kirankumargonti/projects/4_vcp0kj.webp',
        projectDescription:
          'landing page with the time that we can interact with it and save our name/focus to local storage.',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Dynamic-Landing-Page-With-Time--Using-HTML-CSS-JavaScript',
          download:
            'https://codeload.github.com/kirankumargonti/Dynamic-Landing-Page-With-Time--Using-HTML-CSS-JavaScript/zip/master',
          previewURL:
            'https://kirankumargonti.github.io/Dynamic-Landing-Page-With-Time--Using-HTML-CSS-JavaScript/',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          javascript: 'javascript',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Face Recognition examples with Python',
        projectImage:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/768px-Python-logo-notext.svg.png',
        projectDescription: 'Examples for Python Face Recognition library ',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Python-Face-Recognition-examples',
          download:
            'https://codeload.github.com/kirankumargonti/Python-Face-Recognition-examples/zip/master',
        },

        projectTechStack: {
          python: 'python',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Tic Tak Toe Game With Python',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/Tic-Tac-Toe/master/demo.jpg',
        projectDescription: 'Tic Tac Toe game with python',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/Tic-Tac-Toe',
          download:
            'https://codeload.github.com/kirankumargonti/Tic-Tac-Toe/zip/master',
        },

        projectTechStack: {
          python: 'python',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Currency and percentage Converters With React',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/React-Converters/master/demo.png',
        projectDescription:
          'Currency and percentage Converters With React Hooks and utilizing the third party API ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/React-Converters',
          download:
            'https://codeload.github.com/kirankumargonti/React-Converters/zip/master',
          previewURL: 'https://github.com/kirankumargonti/React-Converters',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          react: 'react',
        },
      },
      {
        projectID: uuid(),
        projectName: 'City Tour Website with react',
        projectImage:
          'https://res.cloudinary.com/kirankumargonti/image/upload/v1600919585/Kirankumargonti/projects/10_k5qy9x.webp',
        projectDescription: 'A Simple tour website Project with react',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/City-Tour-React',
          download:
            'https://codeload.github.com/kirankumargonti/City-Tour-React/zip/master',
          previewURL: 'https://kirankumargonti.github.io/City-Tour-React/',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          react: 'react',
        },
      },
      {
        projectID: uuid(),
        projectName: 'React Advice App',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/React-Advice-App/master/public/demo.png',
        projectDescription:
          'React Advice App with react hooks utilizing a third party API.',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/React-Advice-App',
          download:
            'https://codeload.github.com/kirankumargonti/React-Advice-App/zip/master',
          previewURL: 'https://kirankumargonti.github.io/React-Advice-App/',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          react: 'react',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Google Chrome Extension',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/Google-Chrome-Extension/master/images/extension_pic.jpg',
        projectDescription:
          'How to create google chrome extension and this is my personal chrome extension which navigates to my social links ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/Google-Chrome-Extension',
          download:
            'https://codeload.github.com/kirankumargonti/Google-Chrome-Extension/zip/master',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
        },
      },
      {
        projectID: uuid(),
        projectName: ' Todo list with react',
        projectImage:
          'https://res.cloudinary.com/kirankumargonti/image/upload/v1600922112/Kirankumargonti/projects/11_mqg5c5.webp',
        projectDescription: 'A Simple React Todo List app',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/React-Todo-List',
          download:
            'https://codeload.github.com/kirankumargonti/React-Todo-List/zip/master',
          previewURL: 'https://kirankumargonti.github.io/React-Todo-List/',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          bootstrap: 'bootstrap',
          react: 'react',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Restaurant Management System',
        projectImage:
          'https://res.cloudinary.com/kirankumargonti/image/upload/v1600923164/Kirankumargonti/projects/5_vol77a.webp',
        projectDescription: 'A Restaurant website with order facility',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Restaurant-Management-System',
          download:
            'https://codeload.github.com/kirankumargonti/Restaurant-Management-System/zip/master',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          bootstrap: 'bootstrap',
          mysql: 'mysql',
          php: 'php',
          Javascript: 'javascript',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Hospital admin Dashboard',
        projectImage:
          'https://res.cloudinary.com/kirankumargonti/image/upload/v1600923150/Kirankumargonti/projects/3_ztpe9u.webp',
        projectDescription:
          'Online Hospital Admin Dashboard, The Communication Between the Doctor, patient and admin ',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Hospital--admin--Dashboard',
          download:
            'https://codeload.github.com/kirankumargonti/Hospital--admin--Dashboard/zip/master',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          bootstrap: 'bootstrap',
          angular: 'angular',
          firebase: 'firebase',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Contact Form PHPMailer',
        projectImage:
          'https://res.cloudinary.com/kirankumargonti/image/upload/v1600923121/Kirankumargonti/projects/7_zvputp.webp',
        projectDescription:
          'Sending Contact Form Details From your Website to the Gmail Account Using PHPMailer ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/Contact-Form-PHPMailer',
          download:
            'https://codeload.github.com/kirankumargonti/Contact-Form-PHPMailer/zip/master',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          php: 'php',
          bootstrap: 'bootstrap',
        },
      },
      /* end */
    ],
    timeline: [
      {
        title: 'Frontend Developer',
        description: 'Software Developer',
        dateOfJoining: 'Apr 05, 2020',
        end: 'Apr 10, 2021',
        companyName: 'Avyuktha LTD',
        location: 'Cyprus, Europe - (Remote)',
      },
      {
        title: 'UI/UX Designer and Developer',
        companyName: 'Adobe Creative Technologies',
        duration: 'July 2018 - No expiration date',
        location: 'Guru Nanak Institute of Technology',
      },
      {
        title: 'Bachelor of Technology',
        stream: 'Computer science and engineering',
        duration: '2015 July - 2019 April',
        collegeName: 'Guru Nanak Institute of Technology - Hyderabad',
      },
      {
        title: 'Technical Lead (Frontend Developer - web3)',
        description: 'Software Developer',
        duration: '2021 June - 2023 Jan',
        companyName: 'CodeZero2Pi Pvt Ltd',
        location: 'Noida, Uttar Pradesh, India - (Remote)',
      },
      {
        title: 'Frontend Developer- SDE - 2',
        description: 'Software Developer',
        dateOfJoining: [2023, 1, 1, 0, 0, 0],
        companyName: 'Virtual Internships',
        location: 'London, England, United Kingdom - (Remote)',
      },
    ],
  },
}

export default data
