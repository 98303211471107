import React from 'react'
import DAppProjects from './DAppProjects'

const Dapps = () => {
  return (
    <section className='latest_projects'>
      <div className='container'>
        <div className='section_title'>
          <h1>DApps (Decentralized applications)</h1>
        </div>
      </div>
      <DAppProjects />
    </section>
  )
}

export default Dapps
