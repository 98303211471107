import React from 'react'

// data
import data from '../Data/data'

function About() {
  const {
    image,
    fullBio,
    name,
    resume,
  } = data.kirankumargonti.personalInformation
  return (
    <section className='about' id='about'>
      <div className='container'>
        <div className='section_title'>
          <h1>About me</h1>
        </div>
        <div className='row'>
          <div className='col-lg-5 m-auto'>
            <div className='about_left'>
              <img src={image} alt='' />
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='about_right'>
              <h1>H!, I'm {name}</h1>
              <h3>Frontend Architect | 🪐 Web3 Alchemist 🪄</h3>
              <p>{fullBio}</p>
              <a href={resume} download 
               className='download_resume'>
                Download Resume
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
