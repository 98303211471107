import React, {useState, useEffect} from 'react'
import {MdWork} from 'react-icons/md'
import {FcGraduationCap} from 'react-icons/fc'
import {FaCertificate} from 'react-icons/fa'
import intervalToDuration from 'date-fns/intervalToDuration'

// data
import data from '../Data/data'

const Timeline = () => {
  const [result, setResult] = useState('')
  const timeline = data.kirankumargonti.timeline
  const companyDetails = timeline[0]
  const ui_ux_developer = timeline[1]
  const eduction = timeline[2]
  const codeZeroToPi = timeline[3]
  const vi = timeline[4]

  const currentDate = vi.dateOfJoining

  useEffect(() => {
    const result = intervalToDuration({
      start: new Date(),
      end: new Date(
        currentDate[0],
        currentDate[1],
        currentDate[2],
        currentDate[3],
        currentDate[4],
        currentDate[5]
      ),
    })
    setResult(result)
  }, [])

  return (
    <section className='timeline__'>
      <div className='section_title'>
        <h1>Timeline</h1>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='main-timeline'>
              <div className='timeline'>
                {/* 1st */}
                <div className='timeline-icon'>
                  <span className='year'>
                    <MdWork className='timeline__icon' />
                  </span>
                </div>

                <div className='timeline-content'>
                  <h3 className='title'>{vi.title}</h3>
                  <p className='description'>{vi.companyName}</p>
                  <p className='description'>{vi.location}</p>
                  {result && (
                    <p className='description'>
                      {result.years > 1
                        ? `${result.years} Years`
                        : result.years === 1
                        ? `${result.years} Year`
                        : null}{' '}
                      {result.months > 1
                        ? `${result.months} Months`
                        : result.months === 1
                        ? `${result.months} Month`
                        : null}{' '}
                      {result.days > 1
                        ? `${result.days} Days`
                        : result.days === 1
                        ? `${result.days} Day`
                        : null}{' '}
                      <span className='present'>Present</span>
                    </p>
                  )}
                </div>
              </div>
              {/* rapid */}
              <div className='timeline'>
                <div className='timeline-icon'>
                  <span className='year'>
                    <MdWork className='timeline__icon' />
                  </span>
                </div>
                <div className='timeline-content'>
                  <h3 className='title'>{codeZeroToPi.title}</h3>
                  <p className='description'>{codeZeroToPi.companyName}</p>
                  <p className='description'>{codeZeroToPi.location}</p>
                  <p className='description'>1 Year 8 Months</p>
                </div>
              </div>
              {/* rapid -end */}
              {/* 2nd */}
              <div className='timeline'>
                <div className='timeline-icon'>
                  <span className='year'>
                    <MdWork className='timeline__icon' />
                  </span>
                </div>
                <div className='timeline-content'>
                  <h3 className='title'>{companyDetails.title}</h3>
                  <p className='description'>{companyDetails.companyName}</p>
                  <p className='description'>{companyDetails.location}</p>
                  <p className='description'>1 Year 5 Days</p>
                </div>
              </div>

              {/* 3rd */}
              <div className='timeline'>
                <div className='timeline-icon'>
                  <span className='year'>
                    <FaCertificate className='timeline__icon' />
                  </span>
                </div>
                <div className='timeline-content'>
                  <h3 className='title'>{ui_ux_developer.title}</h3>
                  <p className='description'>{ui_ux_developer.companyName}</p>
                  <p className='description'>{ui_ux_developer.location}</p>
                  <p className='description'>{ui_ux_developer.duration}</p>
                </div>
              </div>
              {/* 4rth */}
              <div className='timeline'>
                <div className='timeline-icon'>
                  <span className='year'>
                    <FcGraduationCap className='timeline__icon' />
                  </span>
                </div>
                <div className='timeline-content'>
                  <h3 className='title'>{eduction.title}</h3>
                  <p className='description'>{eduction.companyName}</p>
                  <p className='description'>{eduction.stream}</p>
                  <p className='description'>{eduction.collegeName}</p>
                  <p className='description'>{eduction.duration}</p>
                </div>
              </div>
              {/* 5th*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Timeline
