import React from 'react'
import {
  SiHtml5,
  SiCss3,
  SiBootstrap,
  SiJavascript,
  SiNodedotjs,
  SiGit,
  SiGithub,
  SiReact,
  SiMongodb,
  SiMysql,
  SiPostman,
  SiRedux,
  SiVisualstudiocode,
  SiNextdotjs,
  SiAtom,
  SiFirebase,
  // SiMaterialui,
  SiSass,
  SiTypescript,
  SiTailwindcss,
  SiAdobephotoshop,
  SiAdobexd,
  SiEthereum,
  SiWeb3Dotjs,
} from 'react-icons/si'
import {AiOutlineAntDesign} from 'react-icons/ai'
import {FaCode} from 'react-icons/fa'

function Skills() {
  return (
    <section className='skills' id='skills'>
      <div className='section_title'>
        <h1>Skills</h1>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='programming'>
              <h2>DApp Development</h2>
              <div className='icon_container'>
                <div className='icon_wrapper'>
                  <SiWeb3Dotjs className='icon' />
                  <span className='tooltiptext'>Web3.js</span>
                </div>
                <div className='icon_wrapper'>
                  <img
                    src='https://c.gitcoin.co/grants/7f2bdd3cbd59dcaaa1c4b54c5b73a952/ethers-gitcoin.png'
                    alt='ethers'
                  />
                  <span className='tooltiptext'>Ethers.js</span>
                </div>
                <div className='icon_wrapper'>
                  <img src='https://wagmi.sh/og.png' alt='Wagmi' />
                  <span className='tooltiptext'>Wagmi</span>
                </div>
                <div className='icon_wrapper'>
                  <img
                    src=' https://www.rainbowkit.com/rainbow.svg'
                    alt='RainbowKit'
                  />
                  <span className='tooltiptext'>RainbowKit</span>
                </div>
                <div className='icon_wrapper text_tech'>
                  <p className='m-0'>Web3-React</p>
                  <span className='tooltiptext'>Web3-React</span>
                </div>
                <div className='icon_wrapper text_tech'>
                  <p className='m-0'>Web3-Modal</p>
                  <span className='tooltiptext'>Web3-Modal</span>
                </div>
                <div className='icon_wrapper text_tech'>
                  <p className='m-0'>useDapp</p>
                  <span className='tooltiptext'>useDapp</span>
                </div>
                <div className='icon_wrapper text_tech'>
                  <p className='m-0'>Solana wallet adapter</p>
                  <span className='tooltiptext'>Solana wallet adapter</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='programming'>
              <h2>Crypto Wallets</h2>
              <div className='icon_container'>
                <div className='icon_wrapper'>
                  <img
                    src='https://img.icons8.com/color/344/metamask-logo.png'
                    alt='ethers'
                  />
                  <span className='tooltiptext'>Metamask</span>
                </div>
                <div className='icon_wrapper'>
                  <img
                    src='https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/bftsslxvhe2yaih6nyl9'
                    alt=''
                  />
                  <span className='tooltiptext'>Wallet Connect</span>
                </div>
                <div className='icon_wrapper'>
                  <img
                    src='https://play-lh.googleusercontent.com/wrgUujbq5kbn4Wd4tzyhQnxOXkjiGqq39N4zBvCHmxpIiKcZw_Pb065KTWWlnoejsg'
                    alt=''
                  />
                  <span className='tooltiptext'>Coinbase</span>
                </div>
                <div className='icon_wrapper'>
                  <img
                    src='https://pbs.twimg.com/profile_images/1293288961800933376/rtDOqMXY_400x400.jpg'
                    alt=''
                  />
                  <span className='tooltiptext'>Fortmatic</span>
                </div>
                <div className='icon_wrapper'>
                  <img
                    src='https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/sqzgmbkggvc1uwgapeuy'
                    alt=''
                  />
                  <span className='tooltiptext'>Phantom</span>
                </div>
                <div className='icon_wrapper'>
                  <img
                    src='https://play-lh.googleusercontent.com/waPoKLrd8VeNAmRt6Nv0k4Dph8NHkMjqnreU9UHBKwhLCs_02C7yq4P5k0ebd0G6qZw'
                    alt=''
                  />
                  <span className='tooltiptext'>Solflare</span>
                </div>
                <div className='icon_wrapper'>
                  <img
                    src='https://play-lh.googleusercontent.com/AiZrq1ijlrIp-kI16QoKzZl6nNLLaAWTiHjzH7QD9gsc04tDqidp_YzQskgTCdlPP7HA'
                    alt=''
                  />
                  <span className='tooltiptext'>MEW</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='programming'>
              <h2>Programming</h2>
              <div className='icon_container'>
                <div className='icon_wrapper'>
                  <SiJavascript className='icon' />
                  <span className='tooltiptext'>Javascript</span>
                </div>
                <div className='icon_wrapper'>
                  <SiEthereum className='icon' />
                  <span className='tooltiptext'>Solidity-Ethereum</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='web'>
              <h2>Web Technologies</h2>
              <div className='icon_container'>
                <div className='icon_wrapper'>
                  <SiReact className='icon' />
                  <span className='tooltiptext'>React</span>
                </div>
                <div className='icon_wrapper'>
                  <SiNextdotjs className='icon' />
                  <span className='tooltiptext'>Next js</span>
                </div>

                <div className='icon_wrapper'>
                  <SiNodedotjs className='icon' />
                  <span className='tooltiptext'>Node.js</span>
                </div>

                <div className='icon_wrapper'>
                  <SiTailwindcss className='icon' />
                  <span className='tooltiptext'>TailwindCSS</span>
                </div>
                <div className='icon_wrapper'>
                  <SiTypescript className='icon' />
                  <span className='tooltiptext'>TypeScript</span>
                </div>
                {/* <div className='icon_wrapper'>
                  <img
                    alt=''
                    src='https://www.vectorlogo.zone/logos/expressjs/expressjs-icon.svg'
                    className='icon'
                  />
                  <span className='tooltiptext'>Express.js</span>
                </div> */}
                <div className='icon_wrapper'>
                  <SiRedux className='icon' />
                  <span className='tooltiptext'>Redux-Toolkit</span>
                </div>
                <div className='icon_wrapper'>
                  <SiBootstrap className='icon' />
                  <span className='tooltiptext'>Bootstrap</span>
                </div>
                <div className='icon_wrapper'>
                  <SiSass className='icon' />
                  <span className='tooltiptext'>SASS/SCSS</span>
                </div>
                {/* <div className='icon_wrapper'>
                  <SiMaterialui className='icon' />
                  <span className='tooltiptext'>Material-ui</span>
                </div> */}
                <div className='icon_wrapper'>
                  <AiOutlineAntDesign className='icon' />
                  <span className='tooltiptext'>Ant Design</span>
                </div>
                <div className='icon_wrapper'>
                  <SiHtml5 className='icon' />
                  <span className='tooltiptext'>HTML</span>
                </div>
                <div className='icon_wrapper'>
                  <SiCss3 className='icon' />
                  <span className='tooltiptext'>CSS</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='database'>
              <h2>Databases</h2>
              <div className='icon_container'>
                <div className='icon_wrapper'>
                  <SiMongodb className='icon' />
                  <span className='tooltiptext'>MongoDB</span>
                </div>
                <div className='icon_wrapper'>
                  <SiMysql className='icon' />
                  <span className='tooltiptext'>MySQL</span>
                </div>
                <div className='icon_wrapper'>
                  <SiFirebase className='icon' />
                  <span className='tooltiptext'>Firebase</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='tools'>
              <h2>Tools & IDE's </h2>
              <div className='icon_container'>
                <div className='icon_wrapper'>
                  <FaCode className='icon' />
                  <span className='tooltiptext'>Remix</span>
                </div>
                <div className='icon_wrapper'>
                  <SiGit className='icon' />
                  <span className='tooltiptext'>Git</span>
                </div>
                <div className='icon_wrapper'>
                  <SiGithub className='icon' />
                  <span className='tooltiptext'>GitHub & CLI</span>
                </div>
                <div className='icon_wrapper'>
                  <SiVisualstudiocode className='icon' />
                  <span className='tooltiptext'>Visual Studio Code</span>
                </div>
                <div className='icon_wrapper'>
                  <SiAtom className='icon' />
                  <span className='tooltiptext'>Atom</span>
                </div>
                <div className='icon_wrapper'>
                  <SiPostman className='icon' />
                  <span className='tooltiptext'>Postman</span>
                </div>
                <div className='icon_wrapper'>
                  <SiAdobexd className='icon' />
                  <span className='tooltiptext'>Adobe XD</span>
                </div>
                <div className='icon_wrapper'>
                  <SiAdobephotoshop className='icon' />
                  <span className='tooltiptext'>Photoshop</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='tools'>
              <h2>Mobile Development</h2>
              <div className='icon_container'>
                <div className='icon_wrapper'>
                  <SiReact className='icon' />
                  <span className='tooltiptext'>React Native</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Skills
