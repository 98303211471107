import React from 'react'
import data from '../Data/data'
import {HashLink as Link} from 'react-router-hash-link'
// import SocialLinks from './SocialLinks'
import CustomButton from '../Common/CustomButton'

function Banner() {
  const {shortBio, designation} = data.kirankumargonti.personalInformation
  return (
    <section className='banner' id='banner'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='banner_content'>
              <h2>🪐 UI Alchemist 🪄</h2>
              <h1>{designation}</h1>
              <p> {shortBio} </p>
              {/* <SocialLinks /> */}
              <div className='banner_buttons'>
                <Link to='/projects' className='voltage-button'>
                  <CustomButton buttonName='Explore my projects' />
                </Link>
                <Link to='/#contact'>
                  <CustomButton buttonName='Lets Chat' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
