import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'

// Pages
import Home from './Pages/Home'
import Projects from './Pages/Projects'
import NotFound from './Pages/NotFound'

// Components
import Header from './Components/Header'
import Footer from './Components/Footer'
import ArrowUp from './Components/ArrowUp'
import ScrollRestoration from './Components/ScrollRestoration'
import ProgressBar from './Components/ProgressBar'
import Loading from './Components/Loading'

function App() {
  return (
    <div className='app'>
      <BrowserRouter>
        <Loading />
        <ProgressBar />
        <Header />
        <Routes>
          <Route
            path='/'
            element={
              <>
                <ScrollRestoration />
                <Home />
              </>
            }
          />
          <Route
            path='/projects'
            element={
              <>
                <ScrollRestoration />
                <Projects />
              </>
            }
          />
          <Route
            path='*'
            element={
              <>
                <ScrollRestoration />
                <NotFound />
              </>
            }
          />
        </Routes>
        <ArrowUp />
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
