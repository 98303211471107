import React from 'react'
import ProjectsBanner from '../Components/ProjectsBanner'
import AllProjects from '../Components/AllProjects'
import Slider from '../Components/Slider'
import {Helmet} from 'react-helmet'
import Dapps from '../Components/Dapps'

// data
import data from '../Data/data'

function Projects() {
  const title = data.kirankumargonti.websiteInformation.projectsTitle

  return (
    <>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta charSet='UTF-8' />
        <meta http-equiv='content-language' content='en' />
        <meta http-equiv='content-type' content='text/html; charset=utf-8' />
        <link rel='canonical' href='https://kirankumargonti.com/projects' />
        <meta name='author' content='Kirankumar Gonti' />
        <title>{title}</title>
        <meta
          name='description'
          content='The massive projects of kirankumar gonti'
        />
        <meta
          name='keywords'
          content='Kirankumar Gonti, Kiran kumar gonti, kiran, gonti, kiran website, kiran portfolio,kirankumar website, kirankumar portfolio,kirankumargonti.ga,kirankumargonti.com,kirankumar.com,kirankumar, gonti, kiran projects ,projects, kirankumar gonti projects, projects of kirankumar gonti, projects of kirankumar, kirankumar projects, coding projects of kirankumar gonti, github projects of kirankumar gonti'
        />
        <meta name='robots' content='index,follow' />
        <meta name='googlebot' content='index,follow' />
        <meta property='og:image:alt' content='Website Logo' />
        <meta property='og:site_name' content='Portfolio website' />
        <meta property='og:locale' content='en_US' />
        <meta property='article:author' content='Kirankumar Gonti' />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@gontikirankumar' />
        <meta name='twitter:creator' content='Kirankumar Gonti' />
        <meta name='twitter:url' content='https://kirankumargonti.com' />
        <meta
          name='twitter:title'
          content='Kirankumar Gonti | Frontend Developer - Web3'
        />
        <meta
          name='twitter:description'
          content="Tech enthusiast Frontend developer specializing mostly in Next js and web3, Interested in Blockchain, Beginner in Solidity and I'm a YouTuber also."
        />
        <meta name='twitter:image' content='./android-chrome-512x512.png' />
        <meta name='twitter:image:alt' content='Website Logo' />
      </Helmet>
      <div className='projects'>
        <ProjectsBanner />
        <Dapps />
        <Slider />
        <AllProjects />
      </div>
    </>
  )
}

export default Projects
