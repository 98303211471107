import React from 'react'
import {HashLink as Link} from 'react-router-hash-link'
import {RiMenu3Line} from 'react-icons/ri'

// data
import data from '../Data/data'

function Header() {
  const navLogo = data.kirankumargonti.websiteInformation.logo
  const resume = data.kirankumargonti.personalInformation.resume
  const fullName = data.kirankumargonti.personalInformation.name

  return (
    <nav className='navbar navbar-expand-md fixed-top'>
      <div className='container'>
        <Link className='navbar-brand' to='/'>
          <img src={navLogo} alt='' />
          <h1>{fullName}</h1>
        </Link>
        <button
          className='navbar-toggler d-lg-none'
          type='button'
          data-toggle='collapse'
          data-target='#collapsibleNavId'
          aria-controls='collapsibleNavId'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <RiMenu3Line className='navbar-toggler-icon' />
        </button>
        <div className='collapse navbar-collapse' id='collapsibleNavId'>
          <ul className='navbar-nav ml-auto mt-2 mt-lg-0'>
            <li className='nav-item ml-auto  mx-lg-4'>
              <Link className='nav-link projects' to='/projects'>
                Projects
              </Link>
            </li>
            <li className='nav-item ml-auto mx-lg-3'>
              <Link className='nav-link' to='/#about'>
                About
              </Link>
            </li>
            <li className='nav-item ml-auto mx-lg-3'>
              <Link className='nav-link' to='/#skills'>
                Skills
              </Link>
            </li>
            <li className='nav-item ml-auto mx-lg-3'>
              <Link className='nav-link' to='/#youtube'>
                YouTube
              </Link>
            </li>
            <li className='nav-item ml-auto mx-lg-3'>
              <Link className='nav-link' to='/#contact'>
                Contact
              </Link>
            </li>
            <li className='nav-item resume ml-auto mx-lg-3'>
              <a className='nav-link' download href={resume}>
                Download Resume
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header
